import axios from "axios";
import { removeTokenFromLocalStorage } from "../../utils/token";

export default async function putCompany(id, companyData) {
  const response = await axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/v1/empresa/editar-empresa/?id=${id}`,
      companyData,
    )
    .then((res) => res)
    .catch((error) => {
      if (error.response && error.response.status === 401) {
          removeTokenFromLocalStorage();
        }
      throw error.response.data.data;
    });

  if (response.data) {
    return response.data;
  }
}
