import React, { useState, useEffect } from "react";
import "../../assets/css/global.css";
import { toast, ToastContainer } from "react-toastify";
import load from "../../assets/img/load.gif";
import ModalConfirm from "components/ModalConfirm/";
import { Card, CardBody, Row, Col } from "reactstrap";
import Pagination from "react-responsive-pagination";
import Actions from "components/Actions";
import TableList from "components/Table";
import ListHeader from "components/ListHeader";
import listCompanies from "repositories/companies/listCompanies";
import deleteCompany from "repositories/companies/deleteCompany";

export default function ListCompanies() {
  document.title = "Listar Empresa";

  const [companiesList, setCompaniesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState("");

  const [loading, setLoading] = useState(false);

  const [companyName, setCompanyName] = useState("");
  const [idToDelete, setIdToDelete] = useState("");

  useEffect(() => {
    getCompaniesList();
  }, [currentPage, filter]);

  const getCompaniesList = async () => {
    setLoading(true);
    const response = await listCompanies(currentPage, 10, filter);
    setCompaniesList(response?.response?.data);
    setTotalPages(response?.response?.pages);
    setLoading(false);
  };

  const handleFilter = (e) => {
    setFilter({ [e.field]: e.value });
  };

  const handleDisableCompany = async () => {
    setLoading(true);

    try {
      await deleteCompany(idToDelete);
      setModal(false);
      toast.success("Empresa desativada com sucesso!", { autoClose: 500 });
    } catch (err) {
      toast.error(err);
    }

    getCompaniesList();
    setLoading(false);
  };

  const [modal, setModal] = useState(false);
  const toggleDeleteModal = (item) => {
    setCompanyName(item?.razaosocial);
    setIdToDelete(item?.id);
    setModal(!modal);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="content">
        <ToastContainer theme="colored" />
        <ModalConfirm
          modal={modal}
          handleConfirm={async () => await handleDisableCompany()}
          toggle={() => setModal(!modal)}
          title={`Deseja realmente desativar a empresa: ${companyName}?`}
        />
        <div>
          <h1>Empresas</h1>
        </div>
        <Row>
          <Col md="12">
            <Card>
              <ListHeader
                createLink="empresas/criar"
                createBtnText="Criar Empresa"
                fieldsToFilter={[
                  { value: "razaosocial", name: "Razão Social" },
                  { value: "nomefantasia", name: "Nome Fantasia" },
                  { value: "cnpj", name: "Documento" },
                  { value: "status", name: "Status" },
                ]}
                handleFilter={handleFilter}
              />

              <CardBody style={{ minHeight: "740px" }}>
                {loading ? (
                  <Row style={{ justifyContent: "center" }}>
                    <img width={300} src={load} alt="load" />
                  </Row>
                ) : (
                  <TableList
                    columns={[
                      "Razão Social",
                      "Nome Fantasia",
                      "Documento",
                      "Status",
                      "Nome do Banco de Dados",
                      "Ações",
                    ]}
                    rows={companiesList?.map((item) => (
                      <tr key={item?.id}>
                        <td>{item?.razaosocial}</td>
                        <td>{item?.nomefantasia}</td>
                        <td>{item?.cnpj.length>0?item?.cnpj:item?.outrosdocumentos}</td>
                        <td>{item?.status}</td>
                        <td>{item?.nomedobanco}</td>
                        <Actions
                          link={`empresas/editar/${item?.id}`}
                          toggleConfirmModal={() => toggleDeleteModal(item)}
                        />
                      </tr>
                    ))}
                  />
                )}
                <Pagination
                  current={currentPage}
                  total={totalPages}
                  onPageChange={(page) => handlePageChange(page)}
                  previousLabel="Anterior"
                  nextLabel="Próximo"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
