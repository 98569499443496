import axios from "axios";
import UseStorage from "hooks/UseStorage";

export default async function postCompany(companyData) {
  const token = UseStorage("get", "token");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/v1/empresa/criar-empresa`,
      companyData,
      config
    )
    .then((res) => res)
    .catch((error) => {
      throw error.response.data.data;
    });

  if (response.data) {
    return response.data;
  }
}
