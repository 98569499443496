import ListUsers from "views/users/ListUsers";
import CreateUser from "views/users/CreateUser";
import EditUser from "views/users/EditUser";
import ListAccesses from "views/accesses/ListAccesses";
import CreateAccess from "views/accesses/CreateAccess";
import ListCompanies from "views/company/ListCompanies";
import CreateCompany from "views/company/CreateCompany";
import EditCompany from "views/company/EditCompany";
import Logout from "views/logout/Logout";

var routes = [
  {
    path: "/empresas",
    name: "Empresas",
    icon: "tim-icons icon-app",
    component: ListCompanies,
    layout: "/admin",
  },
  {
    path: "/empresas/criar",
    name: "Criar Empresa",
    component: CreateCompany,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/empresas/editar/:id",
    name: "Editar Empresa",
    component: EditCompany,
    layout: "/admin",
    invisible: true,
  },
  {
    path: `/usuarios`,
    name: "Usuários",
    icon: "tim-icons icon-badge",
    component: ListUsers,
    layout: "/admin",
  },
  {
    path: "/usuarios/criar",
    name: "Criar Usuário",
    component: CreateUser,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/usuarios/editar/:id",
    name: "Editar Usuário",
    component: EditUser,
    layout: "/admin",
    invisible: true,
  },
  {
    path: `/acessos`,
    name: "Acessos",
    icon: "tim-icons icon-badge",
    component: ListAccesses,
    layout: "/admin",
  },
  {
    path: "/acessos/criar",
    name: "Criar Acesso",
    component: CreateAccess,
    layout: "/admin",
    invisible: true,
  },
  {
    path: `/logout`,
    name: "Sair",
    component: Logout,
    layout: "/admin",
  },
];

export default routes;
