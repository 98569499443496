import React, { useEffect, useState } from "react";

export default function SelectGeneric({ list, onChange, value }) {
  const [selectedId, setSelectedId] = useState(value || "");

  useEffect(() => {
    // Define a seleção inicial ao montar o componente ou quando `value` muda
    setSelectedId(value); // Atualiza o ID selecionado
    if (value) {
      const selectedItem = list?.find((company) => company.id == value);
      if (selectedItem) {
        onChange(selectedItem); // Chama `onChange` com a empresa inicial
      }
    }
  }, [value, list, onChange]);

  const handleChange = (event) => {
    
    const selectedId = event.target.value;
    
    setSelectedId(selectedId);
    
    const selectedItem = list?.find((company) => company.id == selectedId);

    if (selectedItem) {
      onChange(selectedItem);
    }
  };

  return (
    <select value={selectedId} onChange={handleChange} className="form-control">
      <option value="">Selecione uma empresa...</option>
      {list.map((company) => (
        <option key={company.id} value={company.id}>
          {company.razaosocial}
        </option>
      ))}
    </select>
  );
}
