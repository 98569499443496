import { useState } from "react";
import { toast } from "react-toastify";
import { Input } from "reactstrap";
import validateCpf from "utils/cpf";
import { maskCpf } from "utils/masks";

export const OutrosInput = ({ ...field }) => {
  const [isInvalid, setIsInvalid] = useState(false);

  const handleInput = async (e) => {
    const { value } = e.target;

    field.onChange(value);

    
    if (value.length === 0) {
      toast.error("Campo Outros vazio!", 4000);
      setIsInvalid(true);
      return;
    } else {
      setIsInvalid(false);
    }
  };

  const handleBlur = async (e) => {
    const { value } = e.target;

    if (value.length === 0) {
      toast.error("Campo Outros vazio!", 2000);
      setIsInvalid(true);
    }
  };

  return (
    <Input
      {...field}
      onBlur={handleBlur}
      invalid={isInvalid}
      onChange={handleInput}
    />
  );
};
