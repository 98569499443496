import axios from "axios";
import UseStorage from "hooks/UseStorage";
import { removeTokenFromLocalStorage } from "../../utils/token";

export default async function postCompany(companyData) {
  const token = UseStorage("get", "token");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/empresa/criar-empresa`,
      companyData,
      config
    );
    
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      removeTokenFromLocalStorage();
    }
    throw error.response.data.data;
  }
}
