import ListFees from "components/ListFees";
import { toast } from "react-toastify";
import { Button, Table } from "reactstrap";

const PricingRanges = ({
  isProductEnabled,
  priceScheme,
  ranges,
  setRanges,
}) => {
  const updateValuesTaxes = (name, value, indexRow) => {
    const newList = ranges.map((item, index) => {
      if (index !== indexRow) return item;

      return {
        ...item,
        [name]: value,
      };
    });

    // Se alterou o end_quantity de uma faixa, atualiza o start_quantity da próxima faixa
    if (name === "end_quantity" && indexRow + 1 < newList.length) {
      newList[indexRow + 1] = {
        ...newList[indexRow + 1],
        start_quantity: Number(value) + 1
      };
    }

    setRanges(newList);
  };

  const onDeleteRow = (indexRow) => {
    const newList = ranges.filter((_, index) => {
      return index !== indexRow;
    });
    setRanges(newList);
  };

  const addTrTable = () => {
    // Verifica se a última faixa tem um valor de término válido
    if (!ranges[ranges.length - 1]?.end_quantity) {
      toast.error("Preencha o campo de Término da última faixa!");
      return;
    }

    ranges.map((element) => {
      element.price = parseFloat(element.price);
    });
    
    // Guarda o valor do excedente da última faixa
    const lastRangeIndex = ranges.length - 1;
    const lastOveragePrice = ranges[lastRangeIndex]?.overage_price;
    
    // Valida se o início não é maior ou igual ao término
    if (
      ranges[lastRangeIndex]?.start_quantity >=
      ranges[lastRangeIndex]?.end_quantity
    ) {
      toast.error("Término não pode ser menor ou igual que o Início");
      return;
    }
    
    // Limpa o excedente de todas as faixas anteriores, inclusive a última
    const updatedRanges = ranges.map((range, index) => ({
      ...range,
      overage_price: null
    }));
    
    // Garante que a primeira faixa comece em 0
    updatedRanges[0].start_quantity = 0;
    
    // Adiciona nova faixa com o início sendo o término da última + 1
    // e o excedente recebendo o valor da faixa anterior
    const newRange = {
      start_quantity: Number(updatedRanges[lastRangeIndex].end_quantity) + 1,
      end_quantity: null,
      price: null,
      overage_price: lastOveragePrice,
    };
    
    setRanges([...updatedRanges, newRange]);
  };

  return (
    <>
      <label>Faixas *</label>
      <Table className="tablesorter" responsive>
        <thead style={{ color: "#fff" }}>
          <tr>
            <td className="border-none">Início</td>
            <td className="border-none">Término</td>
            <td className="border-none">Preço</td>
            <td className="border-none">Excedente</td>
          </tr>
        </thead>
        <tbody>
          {ranges.map((trItem, indexRow) => (
            <ListFees
              isEnableEditing={isProductEnabled}
              priceScheme={priceScheme}
              updateValuesTaxes={updateValuesTaxes}
              onDeleteRow={onDeleteRow}
              trItem={trItem}
              indexRow={indexRow}
              trList={ranges}
            ></ListFees>
          ))}
        </tbody>
      </Table>
      <Button
        hidden={!isProductEnabled}
        onClick={() => {
          (ranges[ranges.length - 1] !== ranges[0] &&
            ranges[ranges.length - 1]?.end_quantity == null) ||
          0
            ? toast.error("Preencha o campo de Término!")
            : addTrTable();
        }}
        color="info"
        style={{ marginBottom: "30px" }}
      >
        Adicionar Faixa
      </Button>
    </>
  );
};

export default PricingRanges;
