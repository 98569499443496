import CreateButton from "components/CreateButton";
import FilterSearch from "components/FilterSearch";
import { CardHeader, Row } from "reactstrap";

export default function ListHeader({
  createLink,
  createBtnText,
  fieldsToFilter,
  handleFilter,
}) {
  return (
    <CardHeader>
      <Row style={{ marginBottom: "20px" }}>
        <CreateButton link={createLink} btnText={createBtnText} />

        <FilterSearch fieldsToFilter={fieldsToFilter} onSearch={handleFilter} />
      </Row>
    </CardHeader>
  );
}
