import React from "react";
import ReactDOM from "react-dom";
import SessionExpired from "components/Sessao/SessionExpired";
import { toast } from "react-toastify";
import UseStorage from "../hooks/UseStorage";

export const removeTokenFromLocalStorage = () => {

  const modalContainer = document.createElement("div");
  modalContainer.id = "session-expired-modal";
  document.body.appendChild(modalContainer);

  ReactDOM.render(<SessionExpired />, modalContainer);

  UseStorage("remove", "token");
  UseStorage("remove", "empresas");

  // Exibir notificação
  toast.error("Saiu com sucesso!", { autoClose: 2000 });

  setTimeout(() => {
    window.location.href = "/login";
  }, 2000); 
};
