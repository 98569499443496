import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { Input } from "reactstrap";
import getCnpj from "repositories/cnpj/getCnpj";
import validateCnpj from "utils/cnpj";
import { removeMask } from "utils/masks";
import { maskCep } from "utils/masks";
import { maskCnpj } from "utils/masks";

export const CnpjInput = ({ setValue, validateDocument, setValidateDocument, ...field }) => {
  const [isInvalid, setIsInvalid] = useState(false);

  const handleCnpj = ({ estabelecimento, razao_social, nome_fantasia }) => {
    if(estabelecimento) {
      if(estabelecimento.atividade_principal?.id) setValue("codcnae", estabelecimento.atividade_principal?.id);
      if(estabelecimento.logradouro) setValue("endereco", estabelecimento.logradouro);
      if(estabelecimento.bairro) setValue("bairro", estabelecimento.bairro);
      if(estabelecimento.cidade?.nome) setValue("cidade", estabelecimento.cidade.nome);
      if(estabelecimento.estado?.sigla) setValue("estado", estabelecimento.estado.sigla);
      if(estabelecimento.cep) setValue("cep", maskCep(estabelecimento.cep));
    };
    if(razao_social) setValue("razaosocial", razao_social);
    if(nome_fantasia) setValue("nomefantasia", nome_fantasia);
  };

  const handleInput = async (e) => {
    const { value } = e.target;

    field.onChange(maskCnpj(value));

    if (maskCnpj(value).length != 18) return;
    
    if (validateCnpj(value)) {
      const toastId = toast.loading("Buscando dados do CNPJ, aguarde...");
      const toastCnae = toast.loading("Buscando dados do CNAE, aguarde...");
      try {
        const response = await axios
            .get(`https://publica.cnpj.ws/cnpj/${removeMask(value)}`)
        if (response.status ===200) {
        toast.dismiss(toastCnae);
        toast.success("Código CNAE obtido com sucesso!", { autoClose: 4000 });
        const cnpjData = await getCnpj(removeMask(value));
        handleCnpj(cnpjData);
        setValidateDocument(true);
      }
      } catch (err) {
        if (err.response) {
        const { status, data } = err.response;
        if(status==429){
          toast.error("Erro ao buscar o CNAE! limite de requisições atingidos. Tente novamente em 1 minuto.");
        }
        if(status==400){
          toast.error("Erro ao buscar o CNAE! CNPJ inválido/inexistente!");
        }else{
          toast.error("CNPJ inválido/inexistente!");
        }
        }
      toast.dismiss(toastId);
      toast.dismiss(toastCnae);
      }finally{
      toast.dismiss(toastId);
      toast.dismiss(toastCnae);
    }
    } else {
      toast.error("CNPJ inválido!", 2000);
      setValidateDocument(false);
    }
  };

  const handleBlur = async (e) => {
    const { value } = e.target;

    if (!validateCnpj(value)) {
      toast.error("CNPJ inválido!", 2000);
      setValidateDocument(false);
    }
  };

  return (
    <Input
      {...field}
      onBlur={handleBlur}
      minLength={18}
      onChange={handleInput}
      invalid={!validateDocument}
    />
  );
};
