import { Input, Button } from "reactstrap";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { maskReais } from "utils/masks";

const ListFees = ({
  changeResultRequest,
  updateValuesTaxes,
  onDeleteRow,
  trItem,
  indexRow,
  trList,
  priceScheme,
  isEnableEditing,
}) => {
  const [startQuantity, setStartQuantity] = useState(trItem?.start_quantity || 0);
  const [endQuantity, setEndQuantity] = useState(trItem?.end_quantity);
  const [price, setPrice] = useState(trItem?.price);
  const [overagePrice, setOveragePrice] = useState(trItem?.overage_price);
  const [tempEndQuantity, setTempEndQuantity] = useState(trItem?.end_quantity);

  useEffect(() => {
    setStartQuantity(trItem.start_quantity);
    setEndQuantity(trItem.end_quantity);
    setPrice(trItem.price);
    setOveragePrice(trItem.overage_price);
    setTempEndQuantity(trItem.end_quantity);
  }, [trItem]);

  const messageError = (message) => {
    toast.error(message);
  };

  const validateEndQuantity = (value) => {
    if (!value) return true;
    const numValue = Number(value);
    const numStart = Number(startQuantity);
    return numValue > numStart;
  };

  const handleEndQuantityChange = (e) => {
    const value = e.target.value;
    setTempEndQuantity(value);
    if (validateEndQuantity(value)) {
      setEndQuantity(value);
      updateValuesTaxes(
        "end_quantity",
        Number(value),
        indexRow,
      );
    }
  };

  const handleEndQuantityBlur = () => {
    if (!validateEndQuantity(tempEndQuantity)) {
      messageError("O término deve ser maior que o início");
      setTempEndQuantity(endQuantity);
      return;
    }
    setEndQuantity(tempEndQuantity);
    updateValuesTaxes(
      "end_quantity",
      Number(tempEndQuantity),
      indexRow,
    );
  };

  // Formata o valor inserido para formato decimal (convertendo 5589 para 55.89)
  const formatDecimalValue = (value) => {
    if (!value) return "";
    
    // Remove todos os caracteres não numéricos
    const cleanValue = value.replace(/\D/g, "");
    
    // Se não houver valor, retorne vazio
    if (!cleanValue) return "";
    
    // Converte para formato decimal (dividindo por 100)
    const numericValue = (parseFloat(cleanValue) / 100).toFixed(2);
    return numericValue;
  };

  const handlePriceChange = (e) => {
    const value = e.target.value;
    const formattedValue = formatDecimalValue(value);
    setPrice(formattedValue);
    updateValuesTaxes(
      "price",
      formattedValue,
      indexRow,
    );
  };

  const handleOveragePriceChange = (e) => {
    const value = e.target.value;
    const formattedValue = formatDecimalValue(value);
    setOveragePrice(formattedValue);
    updateValuesTaxes(
      "overage_price",
      formattedValue,
      indexRow,
    );
  };

  const isOveragePriceEnabled = isEnableEditing && 
    (priceScheme === "volume_usage" || priceScheme === "step_usage") && 
    indexRow === trList.length - 1;

  return (
    <tr key={indexRow}>
      <td className="border-none">
        <ToastContainer theme="colored" />
        <Input
          name="start_quantity"
          type="number"
          value={startQuantity || 0}
          disabled
          required={priceScheme === "flat" ? false : true}
          onChange={(e) => {
            setStartQuantity(Number(e.currentTarget.value));
            updateValuesTaxes(
              "start_quantity",
              Number(e.currentTarget.value),
              indexRow,
            );
          }}
        />
      </td>
      <td className="border-none">
        <Input
          name="end_quantity"
          type="number"
          required={priceScheme === "flat" ? false : true}
          value={tempEndQuantity || ""}
          disabled={!isEnableEditing}
          onChange={handleEndQuantityChange}
          onBlur={handleEndQuantityBlur}
        />
      </td>
      <td className="border-none">
        <Input
          required={priceScheme === "flat" ? false : true}
          name="price"
          type="text"
          disabled={!isEnableEditing}
          value={price || ""}
          onChange={handlePriceChange}
        />
      </td>
      <td className="border-none">
        <Input
          required={priceScheme === "flat" ? false : true}
          name="overage_price"
          value={overagePrice || ""}
          type="text"
          onChange={handleOveragePriceChange}
          disabled={!isOveragePriceEnabled}
        />
      </td>
      <td className="border-none font-icon-detail">
        {indexRow === trList.length - 1 && indexRow !== 0 && (
          <Button
            onClick={() => onDeleteRow(indexRow)}
            className="btn-icon deleteBtn"
            color="danger"
            size="sm"
            disabled={!isEnableEditing}
          >
            <i className="fa fa-times deleteBtn" />
          </Button>
        )}
      </td>
    </tr>
  );
};

export default ListFees;
