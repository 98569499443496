import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import UseStorage from "hooks/UseStorage";
import { toast, ToastContainer } from "react-toastify";


export default function Logout() {
  const history = useHistory();

  useEffect(() => {
    const token = UseStorage("get", "token");

    if (token) {
      fetch(`${process.env.REACT_APP_AUTH_SERVER}/auth/logout`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }).catch((error) => console.error("Erro ao realizar logout:", error));
    }
    
    // Remover dados armazenados no localStorage ou sessionStorage
    UseStorage("remove", "token");
    UseStorage("remove", "empresas");

    toast.error("Saiu com sucesso!", { autoClose: 1000 });

    // Redirecionar para a tela de login
    history.push("/login");
  }, [history]);
}
