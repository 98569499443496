import axios from "axios";
import UseStorage from "hooks/UseStorage";

export default async function getCompany(id) {
  const token = UseStorage("get", "token");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios
    .get(`${process.env.REACT_APP_BASE_URL}/v1/empresa/?id=${id}`, config)
    .then((res) => res)
    .catch((error) => {
      throw error.response.data.data;
    });

  return data.response;
}
