import axios from "axios";
import UseStorage from "hooks/UseStorage";
import ReactDOM from "react-dom";
import SessionExpired from "components/Sessao/SessionExpired";
import { toast } from "react-toastify";

export default async function listCompanies(page = 1, limit = 10, filter) {
  const token = UseStorage("get", "token");
  const filterStr = filter ? encodeURIComponent(JSON.stringify(filter)) : "";

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/v1/empresas/?page=${page}&limit=${limit}&filter=${filterStr}`,
      config
    )
    .then((res) => res)
    .catch((error) => {
      const modalContainer = document.createElement("div");
      modalContainer.id = "session-expired-modal";
      document.body.appendChild(modalContainer);

      ReactDOM.render(<SessionExpired />, modalContainer);

      UseStorage("remove", "token");
      UseStorage("remove", "empresas");

      // Exibir notificação
      toast.error("Saiu com sucesso!", { autoClose: 2000 });

      setTimeout(() => {
        window.location.href = "/login";
      }, 2000); 
      throw error.response.data.data;
    });

  return data;
}
