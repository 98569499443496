import Select, { components, SingleValueProps } from 'react-select';
import React, { useEffect, useState } from "react";
import useCompany from "hooks/useCompany";
import AsyncSelect from 'react-select/async';


export const SelectSearchUsers = ({ handleSetResultOptionSelectUser ,nameOfDB = ""}) => {
  const apiCompany = useCompany();

  const [optionSelectUser, setOptionSelectUser] = useState([{ value: 'Pesquise um usuario', label: 'Pesquise um usuario' }])

  const [resultRequestUserSelect, setResultRequestUserSelect] = useState([])

  const handlerOptionSelected = (userSelected) => {
    const filterUserAssociation = resultRequestUserSelect.filter((user) => user.email === userSelected.value)
    handleSetResultOptionSelectUser(filterUserAssociation[0])
  }

  const handleSelectUser =  (valueSelect) => {

      const arrUserOption = []
      const requestUserAssociation = apiCompany.getUserAssociation({ "emailcobranca": valueSelect }).then(({ usuarios }) => {
        setResultRequestUserSelect(usuarios)
        usuarios.forEach((element) => {
    
          if(element.EmpresaModel.nomedobanco !== nameOfDB){
            arrUserOption.push({ value: element?.email, label: `${element?.email} - ${element.EmpresaModel.razaosocial}` })
          }
        })
        setOptionSelectUser(arrUserOption)
      })
     return arrUserOption
  }


  const SingleValue = ({
    children,
    ...props
  }) => (
      <components.SingleValue {...props}>{children}</components.SingleValue>
  );
  const ValueContainer = ({
    children,
    ...props
  }) => (
      <components.ValueContainer {...props}>{children}</components.ValueContainer>
  );
  const IndicatorsContainer = (
    props
  ) => {
    return (
      <div style={{ background: "#27293d", padding:2} }>
        <components.IndicatorsContainer {...props} />
      </div>
    );
  };

const NoOptionsMessage = props => {
  return (
     <components.NoOptionsMessage {...props}>
         Usuário Autorizado Não Encontrado ! 
     </components.NoOptionsMessage>
  );
};
  
  const loadOptions  =  (
    inputValue,
    callback
  ) => {
    setTimeout(() => {
      callback(handleSelectUser(inputValue));
    }, 1000);
  };

  return (
    <>
      <AsyncSelect 
        placeholder="Pesquise um usuario"
       loadOptions={loadOptions}
       onChange={(e) => handlerOptionSelected(e)}
      //  onInputChange={(e)=>handleSelectUser(e)}
       styles={
         {
        singleValue: (base) => (
         {
          ...base,
          background: '#27293d',
          color:"white",
          display: 'flex',
          width:'100%',
        }),

        valueContainer: (base) => (
         {
          ...base,
          background: "#27293d",
          width: '100%',
          padding:5.5,
        }),

        placeholder: (base) => (
         {
          ...base,
          fontSize: '1em',
          backgroundColor: "#27293d",
          color: "white",
          fontWeight: 400,
          
        }),

        container: (base) => (
          {
          ...base,
          backgroundColor: "#27293d",
          padding: 2,
         }),
      }}
      components={{ SingleValue,ValueContainer ,IndicatorsContainer,NoOptionsMessage}}
      />
    </>
  )
}