import SelectBillingTriggerDay from "components/SelectBillingTriggerDay";
import SelectBillingTriggerTypes from "components/SelectBillingTriggerTypes";
import SelectIntervalCount from "components/SelectIntervalCount";
import SelectYesOrNo from "components/SelectYesOrNo";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Col, Form, Input, Row } from "reactstrap";
import { maskPhone } from "utils/masks";
import { maskBrl } from "utils/masks";
import { maskLandline } from "utils/masks";
import { maskCaepf } from "utils/masks";
import { maskCpf } from "utils/masks";
import { maskCnpj } from "utils/masks";
import { maskDbName } from "utils/masks";
import PricingRanges from "components/PricingRanges";
import { CepInput } from "components/CepInput";
import { removeMask } from "utils/masks";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { CnpjInput } from "components/CnpjInput";
import { formatIsoDateToStr } from "utils/date";
import putCompany from "repositories/companies/putCompany";
import { SelectSearchUsers } from "components/SelectSearchUsers";
import SelectPlans from "components/SelectPlans";
import SelectDocumentType from "components/SelectDocumentType";
import { CpfInput } from "components/CpfInput";
import ModalConfirm from "components/ModalConfirm";
import { OutrosInput } from "components/OutrosInput";
import ClipboardIcon from "assets/img/copy.png"; 
import axios from "axios";

export default function EditCompanyForm({ companyInitialValues, id, enviarMensagemParaPai }) {
  const [isAssociation, setIsAssociation] = useState(false);
  const [isDisassociation, setIsDisassociation] = useState(false);
  const [isProductEnabled, setIsProductEnabled] = useState(false);
  const [isOpenPlanType, setIsOpenPlanType] = useState(false);
  const [isTaxEnabled, setIsTaxEnabled] = useState(false);
  const [isTaxStatus, setIsTaxStatus] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [documentType, setDocumentType] = useState("");
  const [validateDocument, setValidateDocument] = useState(false);
  const [hasASignature, setHasASignature] = useState(false);
  const [listCompanyAssociation, setListCompanyAssociation] = useState([]);
  const [planKey, setPlanKey] = useState();
  const [productKey, setProductKey] = useState();
  const [selectedUsage, setSelectedUsage] = useState("");
  const [valuesPlan, setValuesPlan] = useState([]);
  const [currentPrice, setCurrentPrice] = useState();
  const [employesActives, setEmployesActives] = useState(0);
  const [isDiscountEnabled, setIsDiscountEnabled] = useState(false);
  const [discountApply, setDiscountApply] = useState(false);
  const [discountType, setDiscountType] = useState("amount");
  const [criado_em, setCriado_em] = useState();
  const [atualizado_em, setAtualizado_em] = useState();
  const [tipoDesconto, setTipoDesconto] = useState();
  const [percentual, setPercentual] = useState();
  const [quantia, setQuantia] = useState();
  const [ciclos, setCiclos] = useState();
  const [desconto_status, setDesconto_status] = useState();
  const [previousIntervalCount, setPreviousIntervalCount] = useState(null);
  const [needsNewSubscription, setNeedsNewSubscription] = useState(false);

  const [ranges, setRanges] = useState([
    {
      start_quantity: 0,
      end_quantity: 0,
      price: 0,
      overage_price: null,
    },
  ]);

  const history = useHistory();

  const verifyDocument = (document, outrosdocumentos) => {
    if ((outrosdocumentos ?? "").length > 0) { 
      return "outro";
    } else {
      if (document.length === 11) {
        return "cpf";
      } else if (document.length === 14) {
        return "cnpj";
      }
      return "outro"
    }
  };

  const verifyMask = (typeDocument, document) => {
    if (typeDocument === "cnpj") return maskCnpj(document);
    if (typeDocument === "cpf") return maskCpf(document);
    return document;
  };

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
    getValues
  } = useForm();

  const getDefaultValues = () => {
    const data = companyInitialValues;
    const produto = data?.produto;
    const assinatura = data?.assinatura;
    const responseVerifyDocument = verifyDocument(data.cnpj,data.outrosdocumentos);

    setDocumentType(verifyDocument(data.cnpj,data.outrosdocumentos));
    setPlanKey(assinatura?.keyplano || "");
    setProductKey(produto.length > 0 ? produto[0].precificacao[0]?.keyitem : "");
    setValidateDocument(true);
    setListCompanyAssociation(data?.empresasAssociadas);
    setHasASignature(data?.possuiAssociacao);

    // SETAR MANUALMENTE OS VALORES, DEFAULT FUNCIONA EM STAGING MAS N EM PROD

    setValue("cnpj", verifyMask(responseVerifyDocument, data.cnpj));
    setEmployesActives(data.qtdfuncionarios);
    setValue("razaosocial", data.razaosocial);
    setValue("outrosdocumentos", data.outrosdocumentos??"");
    setValue("nomefantasia", data.nomefantasia);
    setValue("nomedobanco", data.nomedobanco);
    setValue("emailcobranca", data.emailcobranca);
    setValue("number", data.telefone);
    setValue("cpfresponsavel", data.cpfresponsavel);
    setValue("cno", data.cno);
    setValue("caepf", data.caepf);
    setValue("segmento", data.segmento);
    setValue("categoria", data.categoria);
    setValue("codcnae", data.codcnae??"");
    setValue("descdivisaocnae", data.descdivisaocnae??"");
    setValue("descclassecnae", data.descclassecnae??"");
    setValue("dtinserido", data.dtinserido);
    setValue("data_encerramento", data.data_encerramento??'');
    setValue("keyassinatura", assinatura?.keyassinatura??'');
    setValue("cep", data.cep);
    setValue("endereco", data.endereco);
    setValue("bairro", data.bairro);
    setValue("numero", data.numero);
    setValue("complemento", data.complemento);
    setValue("cidade", data.cidade);
    setValue("estado", data.estado);
    setValue("fuso", data.fuso);
    setValue("status", data.status);
    setValue("pontooffline", data.pontooffline);
    setValue("ocultarsaldos", data.ocultarsaldos);
    setValue("customizarfuso", data.customizarfuso);
    setValue("ocultarhistorico", data.ocultarhistorico);
    setValue("terceirizacao", data.terceirizacao);
    setValue("billing_trigger_type", assinatura?.gerarcobranca ?? "beginning_of_period");
    setValue("interval_count", assinatura?.qtdinterval ?? 1);
    setValue("billing_trigger_day", assinatura?.diadacobranca ?? 0);
    setValue("plano", data?.plano?.nome ?? "essencial");
    setValue("priceOne", produto.length > 0 ? produto[0].precificacao[0].precobase : 0);
    setValue("limitefuncionarios", assinatura?.limitefuncionarios ?? "");
    setValue("payment_method_code", assinatura?.metododepagamento ?? "bank_slip");
    setValue("schema_type", "flat");
    setValue("taxa", 0);
    setValue("valortaxa", 0);
    if (data.datafimteste) {
      const formattedDate = new Date(data.datafimteste).toISOString().split("T")[0]; 
      setValue("datafimteste", formattedDate);
    }
    setValue("tipocadastro", data.tipocadastro);
    setSelectedUsage(data.tipocadastro);
    setValue("discount_id",produto[0]?.precificacao[0]?.desconto?.discount_id);

    const criando_dt = new Date(produto[0]?.precificacao[0]?.desconto?.created_at);
    const atualizado_em = new Date(produto[0]?.precificacao[0]?.desconto?.updated_at);

    if (data.produto.length > 0) {
      setValue("schema_type", produto[0]?.tipodeproduto);
      setValue("taxa", !!produto[0]?.taxa);
      setValue("valortaxa", produto[0]?.valortaxa);
      setIsTaxEnabled(!!produto[0].taxa);
      setIsTaxStatus(!!produto[0].taxa);
      setIsDiscountEnabled(produto[0]?.precificacao[0]?.desconto ? true : false)
      setDiscountApply(produto[0]?.precificacao[0]?.desconto ? true : false);
      setCriado_em(criando_dt.toLocaleString('pt-BR', { hour12: false }) + ' ' + criando_dt.toTimeString().split(' ')[1]);
      setAtualizado_em(atualizado_em.toLocaleString('pt-BR', { hour12: false }) + ' ' + atualizado_em.toTimeString().split(' ')[1]);
      setTipoDesconto(produto[0]?.precificacao[0]?.desconto?.discount_type);
      setPercentual(produto[0]?.precificacao[0]?.desconto?.percentage);
      setQuantia(produto[0]?.precificacao[0]?.desconto?.amount);
      setCiclos(produto[0]?.precificacao[0]?.desconto?.cycles);
      setDesconto_status(produto[0]?.precificacao[0]?.desconto?.status);

      // gateway
      setValue("id_taxa", produto[0]?.keytaxa);
      setValue("valor_taxa", produto[0]?.valortaxa);
      setValue("id_produto", produto[0]?.precificacao[0]?.keyitem);
      setValue("valor_produto", produto.length > 0 ? produto[0]?.precificacao[0]?.precobase : 0);
      setValue("id_desconto", produto.length > 0 ? produto[0]?.precificacao[0]?.desconto?.discount_id : 0);
      setValue("valor_desconto", produto.length > 0 ? produto[0]?.precificacao[0]?.desconto?.valor_desconto : 0);
      setValue("valor_total", produto.length > 0 ? produto[0]?.precificacao[0]?.valor_total : 0);
    }

    const defaultRanges =
      produto.length > 0
        ? produto[0].precificacao.map((e) => ({
            start_quantity: e.faixa_inicio,
            end_quantity: e.faixa_fim,
            price: e.precofaixa,
            overage_price: e.excedente,
          }))
        : [
            {
              start_quantity: "",
              end_quantity: "",
              price: "",
              overage_price: "",
            },
          ];

    setRanges(defaultRanges);
  };

  useEffect(() => {
    getDefaultValues();
  }, [companyInitialValues]);

  useEffect(() => {
    if (errors) {
      Object.keys(errors).forEach((errorKey) => {
        toast.error(`Campo "${errorKey}" inválido`);
      });
    }
  }, [Object.keys(errors)]);

  const isFixedPrice = watch("schema_type") == "flat";
  const isMobile = watch("phone_type") == "mobile";
  const isAnnualBilling = watch("interval_count") == 12;
  const isMonthlyBilling = watch("interval_count") == 1;
  const isTriggerDayOfMonth = watch("billing_trigger_type") == "day_of_month";
  const isDocumentTypeValid = documentType != "outro";
  const selectedPlan = watch("plano");
  const isDynamicTable = watch("schema_type") == "dynamic_table";
  const isDiscountFixedPrice = discountType == "amount";
  
  const getData=()=>{
    fetch('/Planosdot8.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
    .then((response)=>  response.json())
    .then((dados)=> {
      setValuesPlan(dados)
    })
    .catch((error)=> console.log(error));
  }

  useEffect(()=>{
    getData();
  },[])

  useEffect(()=>{
    if(employesActives > 0){
      let priceFiltred  = valuesPlan.filter(plano => plano["Colaboradores"] == employesActives);
      if(priceFiltred && priceFiltred.length > 0){
        let price = selectedPlan === "essencial" ? priceFiltred[0]["Mensalidade essencial"] : priceFiltred[0]["Mensalidade avançado"];

        setCurrentPrice(price.replace("R$ ", "").replace(/\./g, "").replace(",", "."));
      }
    }
  },[selectedPlan, employesActives])

  useEffect(() => {
    if (!isDocumentTypeValid) setIsProductEnabled(false);
  }, [isDocumentTypeValid]);

  useEffect(() => {
    if (!isMonthlyBilling && isTriggerDayOfMonth)
      setValue("billing_trigger_type", "beginning_of_period");
  }, [isMonthlyBilling, isTriggerDayOfMonth]);

  const handleSetResultOptionSelectUser = (userSelected) => {
    setSelectedUser(userSelected);
    setValue("interval_count", userSelected?.EmpresaModel?.assinatura?.qtdinterval);
    setValue("billing_trigger_day", userSelected?.EmpresaModel?.assinatura?.diadacobranca);
    setValue("payment_method_code", userSelected?.EmpresaModel?.assinatura?.metododepagamento);
  };

  useEffect(() => {
    const currentIntervalCount = watch("interval_count");
    if (previousIntervalCount !== null && currentIntervalCount !== previousIntervalCount) {
      setNeedsNewSubscription(true);
      setIsProductEnabled(true);
    }
    setPreviousIntervalCount(currentIntervalCount);
  }, [watch("interval_count")]);

  const onSubmit = (data) => {
    delete data.nomedobanco;
    const discountSchema = {
      discount_type: discountType,
      amount: isDiscountFixedPrice ? data.amount : null,
      percentage: !isDiscountFixedPrice ? data.percentage : null,
      cycles: data.cycles,
    };

    if (documentType === "outro") {
      data.cnpj = "";
    } else {
      data.outrosdocumentos = "";
    }
    if (data.datafimteste) {
      const selectedDate = new Date(data.datafimteste);
      selectedDate.setHours(23, 59, 59, 999);
  
      data.datafimteste = selectedDate.toISOString();
    }

    const body = {
      ...data,
      tipocadastro: selectedUsage,
      datafimteste: data.datafimteste || null,
      associar: needsNewSubscription ? 2 : +isAssociation,
      desassociar: +isDisassociation,
      possuiAssociacao: hasASignature,
      outrosdocumentos: data.outrosdocumentos??"",
      discount: +isDiscountEnabled,
      discount_schema: +isDiscountEnabled ? discountSchema : null,
      cnpj: removeMask(data.cnpj),
      editarvindi: isProductEnabled ? 1 : 0,
      fuso: data.fuso,
      numero: data.numero,
      interval: "months",
      telefone: data.number,
      phones: [
        {
          phone_type: data.phone_type,
          number: data.number,
          extension: null,
        },
      ],
      taxa: isTaxEnabled,
      dataProduct: {
        id_item: productKey,
        pricing_schema: {
          price: isFixedPrice ? data.priceOne : 0,
          schema_type: data.schema_type,
          pricing_ranges: !isFixedPrice
            ? ranges.map((range) => ({
                ...range,
                price: parseFloat(range.price),
                overage_price: parseFloat(range.overage_price),
              }))
            : "",
        },
      },
      usuario: selectedUser,
      dataPlan: {
        keyplano: planKey,
        interval: "months",
        interval_count: Number(data.interval_count),
        billing_trigger_type: data.billing_trigger_type,
        billing_trigger_day: Number(data.billing_trigger_day),
        payment_method_code: data.payment_method_code,
        limitefuncionarios: Number(data.limitefuncionarios),
      },
    };

    if (validateDocument && documentType == "cnpj") {
      updateCompany(body);
    } else if (documentType !== "cnpj") {
      updateCompany(body);
    }
  };

  const updateCompany = async (body) => {
    const toastId = toast.loading("Atualizando empresa...");
    try {
      await putCompany(id, body);

      toast.success("Empresa atualizada com sucesso!", 4000);

      setTimeout(() => {
        history.push("/");
      }, 2000);
    } catch (err) {
      toast.error(err.message, 4000);
      console.error(err);
    }
    toast.dismiss(toastId);
  };

  const copyToClipboard = (args) => {
    toast.success("Código CNAE copiado com sucesso!", { autoClose: 4000 });
    const value = getValues(args); 
    if (value) {
      navigator.clipboard.writeText(value).then(() => {});}
  };


  const fetchCnpjData = async () => {
      const cnpjValue = companyInitialValues?.cnpj;
      const outrosdocumentosValue = companyInitialValues?.outrosdocumentos;
      const responseVerifyDocument = verifyDocument(cnpjValue,outrosdocumentosValue);

      const currentCodCnae = companyInitialValues?.codcnae || "" ;
  
      if (!(typeof currentCodCnae === "undefined" || currentCodCnae === "")) {
          return;
      }
  
      if (!cnpjValue) return;

      if (responseVerifyDocument === 'cnpj') {
          const toastId = toast.loading("Buscando dados do CNPJ, aguarde...");
          const toastCnae = toast.loading("Buscando dados do CNAE, aguarde...");
          
          try {
            const response = await axios
            .get(`https://publica.cnpj.ws/cnpj/${cnpjValue}`)
            if(response.status ===429){
              toast.error("Erro ao buscar o CNAE! limite de requisições atingidos");
            }
            if(response.status ===400){
              toast.error("Erro ao buscar o CNAE! CNPJ inválido/inexistente!");
            }
            if (response.status ===200) {
                const data = response.data
                setValidateDocument(true);
                const codCnaePrincipal = data?.estabelecimento?.atividade_principal?.id || "";
                if (codCnaePrincipal) {
                    toast.success("Código CNAE obtido com sucesso!", { autoClose: 4000 });
                    setValue("codcnae", codCnaePrincipal);
                } else {
                    toast.error("Erro ao obter o Código CNAE");
                }
            }
          } catch (err) {
              console.error("Erro na requisição do CNPJ:", err);
  
              // Verifica se a resposta da API está disponível
              if (err.response) {
                  console.error("Detalhes do erro:", err.response);
                  
                  // Obtém o status code e mensagem do erro
                  const { status, data } = err.response;
                  const errorMessage = data?.message || "Erro ao obter os dados do CNPJ.";
  
                  // Exibe o erro com detalhes
                  if(status==429){
                    toast.error("Erro ao buscar o CNAE! limite de requisições atingidos. Tente novamente em 1 minuto.");
                  }
                  if(status==400){
                    toast.error("Erro ao buscar o CNAE! CNPJ inválido/inexistente!");
                  }else{
                  toast.error(`Erro ${status}: ${errorMessage}`, { autoClose: 4000 });
                  }
              } else if (err.request) {
                  // O request foi feito, mas sem resposta do servidor
                  toast.error("Erro na comunicação com o servidor. Tente novamente mais tarde.", 4000);
              } else {
                  // Erro na configuração da requisição
                  toast.error("Erro ao buscar o CNAE! CNPJ inválido/inexistente!", 4000);

              }
  
              setValidateDocument(false);
          }
  
          toast.dismiss(toastId);
          toast.dismiss(toastCnae);
      } else {
          toast.error("CNPJ inválido!", { autoClose: 2000 });
          setValidateDocument(false);
      }
  };
  useEffect(() => {
    fetchCnpjData();
  }, []); 

  useEffect(() => {
    const cnpjValue = companyInitialValues?.cnpj;
    const outrosdocumentosValue = companyInitialValues?.outrosdocumentos;
    const responseVerifyDocument = verifyDocument(cnpjValue,outrosdocumentosValue);
    if (responseVerifyDocument !== 'cnpj') {
      setValue("codcnae", null);
      setValue("descdivisaocnae", null);
      setValue("descclassecnae", null);
    }
    
  }, [documentType, setValue]);
  useEffect(() => {
    if (selectedUsage === "teste") {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        const testEndDate = new Date(watch("datafimteste"));
        testEndDate.setHours(0, 0, 0, 0);

        if (!isNaN(testEndDate.getTime())) {
            if (testEndDate < today) {
                setValue("status", false); 
            } else {
                setValue("status", true);
            }
        }
    }
  }, [watch("datafimteste"), selectedUsage]);

  useEffect(() => {
    const billingTriggerType = watch("billing_trigger_type");
    // Guarda o valor anterior para referência
    const currentBillingTriggerDay = getValues("billing_trigger_day");

    // Só definimos um valor padrão se não houver um valor definido
    if (billingTriggerType === "day_of_month") {
      // Só define 5 como padrão se o valor atual for 0 ou não existir
      if (!currentBillingTriggerDay) {
        setValue("billing_trigger_day", 5);
      }
    } else if (["beginning_of_period", "end_of_period"].includes(billingTriggerType)) {
      setValue("billing_trigger_day", 0);
    }
  }, [watch("billing_trigger_type")]);

  useEffect(() => {
    const schemaType = watch("schema_type");
    if (schemaType === "dynamic_table") {
      setValue("interval_count", 1);
    }
  }, [watch("schema_type")]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md="3">
          <label>Tipo de documento</label>
          <SelectDocumentType setValue={setDocumentType} value={documentType} />
        </Col>
        <Col md="3">
          <label>Documento *</label>
          {documentType.length>0?<Controller
            name={documentType == "outro" ?"outrosdocumentos":"cnpj"}
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => {
              if (documentType == "cnpj") {
                return (
                  <CnpjInput
                    setValue={setValue}
                    setValidateDocument={(bool) => setValidateDocument(bool)}
                    validateDocument={validateDocument}
                    {...field}
                  />
                );
              } else if (documentType == "cpf") {
                setValue("codcnae", null);
                setValue("descdivisaocnae", null);
                setValue("descclassecnae", null);    
                return <CpfInput {...field} />;
              } else {
                setValue("codcnae", null);
                setValue("descdivisaocnae", null);
                setValue("descclassecnae", null);    
                return <OutrosInput {...field} />;
              }
            }}
          />:<></>}
        </Col>
        <Col md="3">
          <label>Razão Social *</label>
          <Controller
            name="razaosocial"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => <Input {...field} />}
          />
        </Col>
        <Col md="3">
          <label>Nome Fantasia *</label>
          <Controller
            name="nomefantasia"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => <Input {...field} />}
          />
        </Col>
        <Col md="3">
          <label>Nome do banco de dados *</label>
          <Controller
            name="nomedobanco"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                {...field}
                required
                disabled
                onChange={(e) => field.onChange(maskDbName(e.target.value))}
              />
            )}
          />
        </Col>
        <Col md="3">
          <label>Email *</label>
          <Controller
            name="emailcobranca"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => <Input {...field} type="email" required />}
          />
        </Col>
        <Col md="3">
          <label>Tipo de Celular *</label>
          <Controller
            name="phone_type"
            control={control}
            render={({ field }) => (
              <select {...field} className="form-control">
                <option value="mobile" selected>
                  Celular
                </option>
                <option value="landline">Telefone</option>
              </select>
            )}
          />
        </Col>

        <Col md="3">
          <label>{isMobile ? "Celular" : "Telefone"} *</label>
          <Controller
            name="number"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                {...field}
                required
                onChange={(e) => {
                  field.onChange(
                    isMobile ? maskPhone(e.target.value) : maskLandline(e.target.value)
                  );
                }}
              />
            )}
          />
        </Col>
      </Row>

      <Row>
        <Col md="3">
          <label>CPF do responsável</label>
          <Controller
            name="cpfresponsavel"
            control={control}
            render={({ field }) => (
              <Input {...field} onChange={(e) => field.onChange(maskCpf(e.target.value))} />
            )}
          />
        </Col>
        <Col md="3">
          <label>CNO</label>
          <Controller
            name="cno"
            control={control}
            render={({ field }) => <Input {...field} maxLength={12} />}
          />
        </Col>
        <Col md="3">
          <label>CAEPF</label>
          <Controller
            name="caepf"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                maxLength={18}
                onChange={(e) => field.onChange(maskCaepf(e.target.value))}
              />
            )}
          />
        </Col>
        <Col md="3">
          <label>Data de criação</label>
          <Controller
            name="dtinserido"
            control={control}
            render={({ field }) => <Input {...field} disabled />}
          />
        </Col>
      </Row>

      <Row>
      <Col md="3">
          <label>Data de desativação</label>
          <Controller
            name="data_encerramento"
            control={control}
            render={({ field }) => <Input {...field} disabled />}
          />
        </Col>
        <Col md="3">
          <label>CEP</label>
          <Controller
            name="cep"
            control={control}
            render={({ field }) => <CepInput setValue={setValue} {...field} />}
          />
        </Col>
        <Col md="3">
          <label>Endereço</label>
          <Controller
            name="endereco"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </Col>
        <Col md="3">
          <label>Bairro</label>
          <Controller
            name="bairro"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </Col>
        <Col md="3">
          <label>Número</label>
          <Controller
            name="numero"
            control={control}
            render={({ field }) => <Input {...field} type="number" />}
          />
        </Col>
        <Col md="3">
          <label>Complemento</label>
          <Controller
            name="complemento"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </Col>
        <Col md="3">
          <label>Cidade</label>
          <Controller
            name="cidade"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </Col>
        <Col md="3">
          <label>Estado</label>
          <Controller
            name="estado"
            control={control}
            render={({ field }) => <Input {...field} maxLength={2} placeholder="UF" />}
          />
        </Col>
      </Row>

      <Row>
        <Col md="3">
          <label>Ponto Offline</label>
          <Controller
            name="pontooffline"
            control={control}
            render={({ field }) => <SelectYesOrNo {...field} stateComponent={!!field.value} />}
          />
        </Col>
        <Col md="3">
          <label>Ocultar Saldos e Intervalo no Aplicativo</label>
          <Controller
            name="ocultarsaldos"
            control={control}
            render={({ field }) => <SelectYesOrNo {...field} stateComponent={!!field.value} />}
          />
        </Col>
        <Col md="3">
          <label>Ocultar Histórico de Batidas no Aplicativo</label>
          <Controller
            name="ocultarhistorico"
            control={control}
            render={({ field }) => <SelectYesOrNo {...field} stateComponent={!!field.value} />}
          />
        </Col>
        <Col md="3">
          <label>Fuso Customizado</label>
          <Controller
            name="customizarfuso"
            control={control}
            render={({ field }) => <SelectYesOrNo {...field} stateComponent={!!field.value} />}
          />
        </Col>
        <Col md="3">
          <label>Fuso Horário</label>
          <Controller
            name="fuso"
            control={control}
            render={({ field }) => (
              <Input type="select" {...field}>
                <option value="-5">Horário Padrão do Acre (GMT-5)</option>
                <option value="-4">Horário Padrão do Amazonas (GMT-4)</option>
                <option value="-3">Horário Padrão de Brasília (GMT-3)</option>
                <option value="-2">Horário Padrão de Fernando de Noronha (GMT-2)</option>
              </Input>
            )}
          />
        </Col>
        <Col md="3">
          <label>Terceirização</label>
          <Controller
            name="terceirizacao"
            control={control}
            render={({ field }) => <SelectYesOrNo {...field} stateComponent={!!field.value} />}
          />
        </Col>
        <Col md="3">
          <label>Funcionários ativos</label>
            <Input
              value={employesActives}
              disabled={true}
            />
        </Col>
      </Row>

      <Row>
        <Col md="3">
          <label>Tipo de Plano *</label>
          <Controller
            name="plano"
            control={control}
            render={({ field }) => (
              <SelectPlans
                openModal={() => {
                  setIsOpenPlanType(true);
                }}
                {...field}
              />
            )}
          />
        </Col>
      </Row>

      <Row>
        <Col md="3">
          <h4 className="title">Situação Cadastral</h4>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <label>Tipo de Cadastro *</label>
          <Controller
            name="tipocadastro"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <select
                {...field}
                className="form-control"
                onChange={(e) => {
                  const value = e.target.value;
                  field.onChange(value);
                  setSelectedUsage(value);
                }}
              >              
                <option value="interno">Uso Interno</option>
                <option value="cliente">Cliente</option>
                <option value="teste">Teste Gratuito</option>
              </select>
            )}
          />
        </Col>
        {selectedUsage === "teste" && (
          <Col md="3">
            <label>Data Fim *</label>
            <Controller
              name="datafimteste"
              control={control}
              rules={{
                required: true,
                validate: (value) => {
                  if (!value) return "Data obrigatória";
                  
                  const datePattern = /^\d{4}-\d{2}-\d{2}$/;
                  if (!datePattern.test(value)) return "Formato inválido";
          
                  const year = parseInt(value.split("-")[0], 10);
                  if (year > 9999) return "Ano inválido";
                  
                  return true;
                }
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="date"
                  className="form-control"
                  onFocus={(e) => e.target.showPicker()}
                  onChange={(e) => {
                    let value = e.target.value;
                    const year = value.split("-")[0];
          
                    if (year.length > 4) {
                      value = value.slice(0, 4) + value.slice(5);
                    }
                    
                    field.onChange(value);
                  }}
                />
              )}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col md="3">
          <label>Status</label>
          <Controller
            name="status"
            control={control}
            render={({ field }) => (
                <SelectYesOrNo {...field} stateComponent={!!field.value} disabled={selectedUsage === "teste"} />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="title">Segmentação</h3>
        </Col>
      </Row>
      <Row
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Col md="3">
          <label>Segmento *</label>
          <Controller
            name="segmento"
            control={control}
            render={({ field }) => <Input {...field} />}
            rules={{
              required: true,
            }}
          />
        </Col>
        <Col md="3">
          <label>Categoria *</label>
          <Controller
            name="categoria"
            control={control}
            render={({ field }) => <Input {...field} />}
            rules={{
              required: true,
            }}
          />
        </Col>
        {documentType ==="cnpj"?
        <Col md="3">
          <label>Código CNAE *</label>
          <Controller
            rules={{
              required: true,
            }}
            name="codcnae"
            control={control}
            render={({ field }) => <Input {...field} disabled />}
          />
        </Col>:null}
        {documentType ==="cnpj"?
        <img
          src={ClipboardIcon}
          alt="Copiar Código CNAE"
          onClick={() => copyToClipboard("codcnae")}
          style={{ 
            cursor: "pointer", 
            marginLeft: "10px", 
            width: "24px", 
            height: "24px" 
          }}
        />:null}
        {documentType ==="cnpj"?
        <Col md="3">
          <label>Descrição Divisão CNAE *</label>
          <Controller
            rules={{
              required: true,
            }}
            name="descdivisaocnae"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </Col>:null}
        {documentType ==="cnpj"?
        <Col md="3">
          <label>Descrição Classe CNAE *</label>
          <Controller
            rules={{
              required: true,
            }}
            name="descclassecnae"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </Col>:null}
      </Row>

      <Row>
        <Col>
          {hasASignature ? (
            <h3 className="title">Desassociar Empresa</h3>
          ) : (
            <h3 className="title">Associar Empresa</h3>
          )}
        </Col>
      </Row>

      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Col md="3">
          <label>Associar</label>
          <SelectYesOrNo
            positiveValue="Sim"
            negativeValue="Não"
            disabled={!isProductEnabled}
            stateComponent={isAssociation}
            onChange={(e) => setIsAssociation(e.target.value)}
          />
        </Col>

        {isAssociation && (
          <Col md={3} style={{ marginTop: "-5px" }}>
            <label>Usuario </label>
            <SelectSearchUsers handleSetResultOptionSelectUser={handleSetResultOptionSelectUser} />
          </Col>
        )}

        {isDocumentTypeValid && (
          <Col md="6">
            <Button
              onClick={() => {
                setIsProductEnabled((prev) => !prev);
              }}
              tag="text"
              color="info"
            >
              {!isProductEnabled ? "Habilitar Produto" : "Desabilitar Produto" }
            </Button>
          </Col>
        )}

        <Col md="6">
          {hasASignature === true && (
            <div>
              <label>Empresas Associadas </label>
              <div className="text-white">
                {listCompanyAssociation.map((e) => {
                  return (
                    e.id != id ? (
                      <p key={e.id}>
                        {e.razaosocial}
                      </p>
                    ) : (
                      <div className="disassociation">
                        <p className={isDisassociation ? "disassociation-p" : ""} key={e.id}>
                          {e.razaosocial}
                        </p>
                        <span
                          className={!isProductEnabled ? "disabled-disassociation" : ""}
                          onClick={isProductEnabled ? () => setIsDisassociation(!isDisassociation) : null}
                        >
                          X
                        </span>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          )}
        </Col>
      </Row>

      <Row style={{ marginTop: "30px" }}>
        <Col>
          <h3 className="title">Dados da Assinatura</h3>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <h4 className="title">Produto</h4>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <label>ID Assinatura</label>
          <Controller
              name="keyassinatura"
              control={control}
              render={({ field }) => <Input {...field} disabled />}
          />
          <label>Tipo de Cálculo *</label>
          <Controller
            name="schema_type"
            control={control}
            render={({ field }) => (
              <select {...field} disabled={!isProductEnabled} className="form-control">
                <option value="flat" selected>
                  Preço Fixo
                </option>
                <option value="step_usage">Fixo por Volume</option>
                <option value="volume_usage">Variavel por Volume</option>
                <option value="dynamic_table">Tabela Dinâmica</option>
              </select>
            )}
          />
        </Col>

        {isDynamicTable ? (
          <>
            <Col md="3">
              <label>Preço Cobrado</label>
                <Input
                  value={currentPrice}
                  disabled={true}
                />
            </Col>
          </>
        ) : isFixedPrice ? (
          <Col md="3">
            <label>Preço *</label>
            <Controller
              name="priceOne"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  disabled={!isProductEnabled}
                  required
                  onChange={(e) => field.onChange(maskBrl(e.target.value))}
                />
              )}
            />
          </Col>
        ) : (
          <Col md="12">
            <PricingRanges
              isProductEnabled={isProductEnabled}
              priceScheme={watch("schema_type")}
              ranges={ranges}
              setRanges={setRanges}
            />
          </Col>
        )}
      </Row>

      <Row>
        <Col md="3">
          <label>Adicionar taxa *</label>
          <SelectYesOrNo
            positiveValue="Sim"
            negativeValue="Não"
            stateComponent={isTaxEnabled}
            onChange={(e) => setIsTaxEnabled(e.target.value)}
            disabled={!isProductEnabled}
          />
        </Col>
        {isTaxEnabled ? (
          <Col md="3">
            <label>Taxa *</label>
            <Controller
              name="valortaxa"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  disabled={!isProductEnabled}
                  required
                  onChange={(e) => field.onChange(maskBrl(e.target.value))}
                />
              )}
            />
          </Col>
        ) : null}
      </Row>

      {/* desconto */}
      <Row>
        <Col md="3">
          <label>Deseja adicionar Desconto?</label>
          <SelectYesOrNo
            positiveValue="Sim"
            negativeValue="Não"
            stateComponent={isDiscountEnabled}
            onChange={(e) => setIsDiscountEnabled(e.target.value)}
            disabled={!isProductEnabled}
          />
        </Col>
        {isDiscountEnabled ? (
          <>
            <Col md="3">
              <label>Tipo de Desconto *</label>
              <select
                disabled={!isProductEnabled}
                onChange={(e) => setDiscountType(e.target.value)}
                className="form-control"
                required
              >
                <option value="amount" selected>
                  Preço Fixo
                </option>
                <option value="percentage">Porcentagem</option>
              </select>
            </Col>
            <Col md="3">
              <label>{isDiscountFixedPrice ? "Preço Fixo *" : "Porcentagem *"}</label>
              <Controller
                name={discountType}
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    disabled={!isProductEnabled}
                    required
                    type={isDiscountFixedPrice ? "text" : "number"}
                    onChange={(e) => {
                      let value = e.target.value;

                      if (isDiscountFixedPrice) {
                        value = maskBrl(value);
                      }

                      // Impede valores acima de 100
                      if (!isDiscountFixedPrice && !isNaN(value) && parseFloat(value) > 100) {
                        value = 100;
                      }

                      return field.onChange(value);
                    }}
                  />
                )}
              />
            </Col>
            <Col md="3">
              <label>Ciclos *</label>
              <Controller
                name="cycles"
                control={control}
                render={({ field }) => (
                  <Input {...field} disabled={!isProductEnabled} type="number" required />
                )}
              />
            </Col>
          </>
        ) : null}
      </Row>

      {discountApply ? (
        <>
        <Row style={{ marginTop: "30px" }}>
          <Col md="4">
            <h4 className="title">Desconto Aplicado</h4>
          </Col>
        </Row>
        <Row>
          <Col md="2">
            <label>Tipo de Desconto</label>
            <Input
              value={tipoDesconto === "amount" ? "Preço Fixo" : "Porcentagem"}
              disabled={true}
            />
          </Col>
          <Col md="1">
            <label>{tipoDesconto === "amount" ? "Preço Fixo" : "Porcentagem"}</label>
            <Input
              value={tipoDesconto === "amount" ? quantia: percentual}
              disabled={true}
            />
          </Col>
          <Col md="1">
            <label>Status</label>
            <Input value={desconto_status  === "active" ? "Ativo" : "Inativo"} disabled={true} type="text" />
          </Col>
          <Col md="1">
            <label>Ciclos</label>
            <Input value={ciclos} disabled={true} type="number" />
          </Col>
          <Col md="3">
            <label>Criação</label>
            <Input
              value={criado_em}
              disabled={true}
            />
          </Col>
          <Col md="3">
            <label>Atualização</label>
            <Input
              value={atualizado_em}
              disabled={true}
            />
          </Col>
      </Row>
      </>
    ) : null}

      <Row style={{ marginTop: "30px" }}>
        <Col md="4">
          <h4 className="title">Plano</h4>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <label>Forma de pagamento</label>
          <Controller
            name="payment_method_code"
            control={control}
            render={({ field }) => (
              <select
                {...field}
                disabled={!isProductEnabled || isAssociation}
                name="payment_method_code"
                className="form-control"
              >
                <option disabled selected>
                  Selecione o Método de Pagamento
                </option>
                <option value="bank_slip">Boleto Bancário</option>
                <option value="credit_card">Cartão de Crédito</option>
                <option value="pix">Pix</option>
              </select>
            )}
          />
        </Col>
      </Row>

      <Row>
        <Col md="3">
          <label>Contagem de intervalo *</label>
          <Controller
            name="interval_count"
            control={control}
            render={({ field }) => (
              <SelectIntervalCount
                {...field}
                ref={null}
                disabled={!isProductEnabled || isAssociation}
                schema_type={watch("schema_type")}
                required
                className="form-control"
              />
            )}
          />
        </Col>

        {(isFixedPrice || (isAnnualBilling && !isDynamicTable)) && (
          <Col md="3">
            <label>Limite de Funcionários *</label>
            <Controller
              name="limitefuncionarios"
              control={control}
              rules={{
                required: true,
                min: 1,
                validate: (value) => {
                  if (value < 1) return "O limite mínimo é 1 funcionário";
                  return true;
                }
              }}
              render={({ field }) => (
                <Input 
                  {...field} 
                  type="number"
                  min="1"
                  disabled={!isProductEnabled} 
                  required={true}
                />
              )}
            />
          </Col>
        )}

        <Col md="3">
          <label>Referência para data de geração da cobrança *</label>
          <Controller
            name="billing_trigger_type"
            control={control}
            render={({ field }) => (
              <SelectBillingTriggerTypes
                {...field}
                ref={null}
                intervalCount={watch("interval_count")}
                disabled={!isProductEnabled}
                required
                className="form-control"
              />
            )}
          />
        </Col>

        <Col md="3">
          {watch("billing_trigger_type") === "day_of_month" && (
            <>
              <label>Dia de cobrança *</label>
              <Controller
                name="billing_trigger_day"
                control={control}
                render={({ field }) => (
                  <select {...field} ref={null} disabled={!isProductEnabled} required className="form-control">
                    {[5, 10, 15, 20, 25, 30].map((value) => (
                      <option key={value} value={value} selected={field.value == value}>
                        {value}
                      </option>
                    ))}
                  </select>
                )}
              />
            </>
          )}
          {["beginning_of_period", "end_of_period"].includes(watch("billing_trigger_type")) && (
            <>
              <label>Dia de cobrança *</label>
              <Controller
                name="billing_trigger_day"
                control={control}
                render={({ field }) => (
                  <select {...field} ref={null} disabled={!isProductEnabled} required className="form-control">
                    <option value="0">Cobrança Imediata</option>
                  </select>
                )}
              />
            </>
          )}
        </Col>
      </Row>

      <Row style={{ marginTop: "30px" }}>
        <Col md="4">
          <h4 className="title">Gateway</h4>
        </Col>
      </Row>
      <Row>
      <Col md="2">
          <label>ID Taxa {isTaxStatus ? "(Ativo)" : "(Inativo)"}</label>
          <Controller
            name="id_taxa"
            control={control}
            render={({ field }) => <Input {...field} disabled/>}
          />
        </Col>
        <Col md="2">
          <label>Valor Taxa {isTaxStatus ? "(Ativo)" : "(Inativo)"}</label>
          <Controller
            name="valor_taxa"
            control={control}
            render={({ field }) => <Input {...field} disabled/>}
          />
        </Col>

        <Col md="2">
          <label>ID Produto</label>
          <Controller
            name="id_produto"
            control={control}
            render={({ field }) => <Input {...field} disabled/>}
          />
        </Col>
        <Col md="2">
          <label>Valor Produto</label>
          <Controller
            name="valor_produto"
            control={control}
            render={({ field }) => <Input {...field} disabled/>}
          />
        </Col>

        <Col md="2">
          <label>ID Desconto</label>
          <Controller
            name="id_desconto"
            control={control}
            render={({ field }) => <Input {...field} disabled/>}
          />
        </Col>
        <Col md="2">
          <label>Valor Desconto</label>
          <Controller
            name="valor_desconto"
            control={control}
            render={({ field }) => <Input {...field} disabled/>}
          />
        </Col>
        <Col md="2">
          <label>Valor Total</label>
          <Controller
            name="valor_total"
            control={control}
            render={({ field }) => <Input {...field} disabled/>}
          />
        </Col>
      </Row>

      <Button disabled={false} className="btn-fill" color="primary" type="submit">
        Salvar
      </Button>
      <ModalConfirm
        modal={isOpenPlanType}
        handleConfirm={() => {
          setIsOpenPlanType(false);
          setValue("plano", "avancado");
        }}
        toggle={() => {
          setIsOpenPlanType(false);
          setValue("plano", "essencial");
        }}
        title={"Deseja ativar o plano avançado para essa empresa?"}
        subTitle=""
      />
    </Form>
  );
}
