import React from "react";

const SelectIntervalCount = ({ ...props }) => {
  let intervalCounts = {
    1: "Mensal",
    3: "Trimestral",
    6: "Semestral",
    12: "Anual",
  };

  if(props.schema_type === "dynamic_table"){
    intervalCounts = {
      1: "Mensal"
    };
  }

  const options = Object.entries(intervalCounts).map(([value, name]) => (
    <option key={value} value={value}>
      {name}
    </option>
  ));

  return (
    <select {...props}>
      <option disabled selected>
        Selecione o periodo dos pagamentos
      </option>
      {options}
    </select>
  );
};

export default SelectIntervalCount;
