import { useState, useEffect, useMemo } from "react";
import getUser from "repositories/users/getUser";
import { InputPassword } from "components/InputPassword";
import SelectYesOrNo from "components/SelectYesOrNo";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Col, Form, Input, Row } from "reactstrap";
import { maskPhone } from "utils/masks";
import { maskCpf } from "utils/masks";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import SelectGeneric from "components/SelectGeneric";

export default function EditUserForm({ onSubmit, id }) {
  const history = useHistory();
  const [user, setUser] = useState();
  const [getOptionsCompany, setOptionsCompany] = useState();

  const { handleSubmit, control, setValue, formState: { errors } } = useForm({
      defaultValues: user,
      values: user,
    });

  const getDefaultValues = async () => {
    const { usuario, acessos } = await getUser(id);

    if (usuario.tipo != "gestor_sistema") {
      history.push("/admin/usuarios");
    }
    setOptionsCompany(acessos)
    setUser({
      ...usuario,
      acessos: acessos.map((e) => e.nomefantasia).join(", "),
    });

    Object.keys(usuario).forEach((key) => {
      setValue(key, usuario[key]);
    });
    setValue('acessos', acessos.map((e) => e.nomefantasia).join(", "));
  };

  useEffect(() => {
    getDefaultValues();
  }, []);

  useEffect(() => {
    if(errors) {
      Object.keys(errors).forEach((errorKey) => {
        toast.error(`Campo "${errorKey}" inválido`);
      });
    }
  }, [Object.keys(errors)]);

  return (
    <div>
      {user ? (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md="12">
              <label>Empresa selecionada</label>
              <Controller
                name="empresa"
                control={control}
                render={({ field}) => (
                  <SelectGeneric 
                    list={getOptionsCompany}
                    onChange={(item) => field.onChange(item.id)}
                    value={field.value} // Passa o ID atual da empresa selecionada
                  />
                )}
              />
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <label>Nome *</label>
              <Controller
                rules={{
                  required: true,
                }}
                name="nome"
                control={control}
                render={({ field }) => <Input required {...field} />}
              />
            </Col>
            <Col md="6">
              <label>CPF</label>
              <Controller
                name="cpf"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onChange={(e) => field.onChange(maskCpf(e.target.value))}
                    maxLength={14}
                  />
                )}
              />
            </Col>
            <Col md="6">
              <label>Email *</label>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Input {...field} type="email" required />
                )}
              />
            </Col>

            <Col md="6">
              <label>Login *</label>
              <Controller
                name="login"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => <Input {...field} />}
              />
            </Col>

            <Col md="6">
              <label>Senha</label>
              <Controller
                name="senha"
                control={control}
                render={({ field }) => <InputPassword {...field} />}
              />
            </Col>

            <Col md="6">
              <label>Telefone</label>
              <Controller
                name="telefone"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onChange={(e) => field.onChange(maskPhone(e.target.value))}
                  />
                )}
              />
            </Col>

            <Col md="6">
              <label>Status</label>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <SelectYesOrNo
                    stateComponent={Boolean(field.value)}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </Col>

            <Col md="6">
              <label>PIS</label>
              <Controller
                name="pis"
                control={control}
                render={({ field }) => <Input {...field} type="number" />}
              />
            </Col>

            <Col md="6">
              <label>Login do App</label>
              <Controller
                name="loginapp"
                control={control}
                render={({ field }) => (
                  <SelectYesOrNo
                    stateComponent={Boolean(field.value)}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </Col>

            <Col md="6">
              <label>Login da Web</label>
              <Controller
                name="loginweb"
                control={control}
                render={({ field }) => (
                  <SelectYesOrNo
                    stateComponent={Boolean(field.value)}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </Col>

            <Col md="6">
              <label>Pin do App</label>
              <Controller
                name="pinapp"
                control={control}
                render={({ field }) => <Input {...field} />}
              />
            </Col>

            <Col md="6">
              <label>Acessos</label>
              <Controller
                name="acessos"
                control={control}
                render={({ field }) => (
                  <Link to={`/admin/acessos/criar`}>
                    <Input
                      {...field}
                      disabled
                      style={{
                        cursor: "pointer",
                        background: "#1d253b",
                        caretColor: "transparent",
                      }}
                    />
                  </Link>
                )}
              />
            </Col>
          </Row>

          <Button
            disabled={false}
            className="btn-fill"
            color="primary"
            type="submit"
          >
            Salvar
          </Button>
        </Form>
      ) : (
        <p>Carregando...</p>
      )}
    </div>
  );
}
