import React from "react";
import ReactDOM from "react-dom";

const SessionExpired = () => {
  const handleClose = () => {
    const modalContainer = document.getElementById("session-expired-modal");
    if (modalContainer) {
      ReactDOM.unmountComponentAtNode(modalContainer);
      modalContainer.remove(); 
      window.location.href = "/login";
    }
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <h1 style={styles.title}>Sessão Expirada</h1>
        <p style={styles.message}>
          Seu acesso foi feito em outro dispositivo e sua sessão foi encerrada.
        </p>
        <button style={styles.button} onClick={handleClose}>
          Fechar
        </button>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modal: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
    textAlign: "center",
    width: "90%",
    maxWidth: "400px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "red",
  },
  message: {
    fontSize: "18px",
    margin: "5px 0",
    color: 'black'
  },
  button: {
    marginTop: "5px",
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#5311D1",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default SessionExpired;
