import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Col } from "reactstrap";

export default function CreateButton({ link, style, btnText }) {
  return (
    <Col md="4">
      <Link to={link}>
        <Button color="info" size="md" style={{ ...style }}>
          {btnText}
        </Button>
      </Link>
    </Col>
  );
}
