const SelectPlans = ({ openModal, onChange, ...field }) => {
  return (
    <select {...field} className="form-control" onChange={(event) => {
      onChange(event);
      if(event.target.value === 'avancado') {
        openModal();
      }
    }}>
      <option value="essencial" selected>
        Essencial
      </option>
      <option value="avancado">Avançado</option>
    </select>
  );
};

export default SelectPlans;
