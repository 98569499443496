import SelectBillingTriggerDay from "components/SelectBillingTriggerDay";
import SelectBillingTriggerTypes from "components/SelectBillingTriggerTypes";
import SelectIntervalCount from "components/SelectIntervalCount";
import SelectYesOrNo from "components/SelectYesOrNo";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Col, Form, Input, Row } from "reactstrap";
import { maskPhone } from "utils/masks";
import { maskBrl } from "utils/masks";
import { maskLandline } from "utils/masks";
import { maskCaepf } from "utils/masks";
import { maskCpf } from "utils/masks";
import { maskDbName } from "utils/masks";
import PricingRanges from "components/PricingRanges";
import { CepInput } from "components/CepInput";
import { removeMask } from "utils/masks";
import postCompany from "repositories/companies/postCompany";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CnpjInput } from "components/CnpjInput";
import { SelectSearchUsers } from "components/SelectSearchUsers";
import SelectPlans from "components/SelectPlans";
import SelectDocumentType from "components/SelectDocumentType";
import { CpfInput } from "components/CpfInput";
import ModalConfirm from "components/ModalConfirm";
import { OutrosInput } from "components/OutrosInput";
import ClipboardIcon from "assets/img/copy.png"; 

export default function CreateCompanyForm() {
  const [isAssociation, setIsAssociation] = useState(false);
  const [isProductEnabled, setIsProductEnabled] = useState(false);
  const [isOpenPlanType, setIsOpenPlanType] = useState(false);
  const [isTaxEnabled, setIsTaxEnabled] = useState(false);
  const [isDiscountEnabled, setIsDiscountEnabled] = useState(false);
  const [discountType, setDiscountType] = useState("amount");
  const [documentType, setDocumentType] = useState("cnpj");
  const [validateDocument, setValidateDocument] = useState(true);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedUsage, setSelectedUsage] = useState("");
  const [valuesPlan, setValuesPlan] = useState([]);
  const [ranges, setRanges] = useState([
    {
      start_quantity: 0,
      end_quantity: 0,
      price: 0,
      overage_price: null,
    },
  ]);

  const history = useHistory();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      datafimteste: null,
      tipocadastro: "interno",
      razaosocial: "",
      nomefantasia: "",
      cnpj: "",
      outrosdocumentos: "",
      nomedobanco: "",
      emailcobranca: "",
      phone_type: "mobile",
      number: "",
      cpfresponsavel: "",
      cno: "",
      caepf: "",
      cep: "",
      endereco: "",
      bairro: "",
      numeroResidencial: "",
      complemento: "",
      cidade: "",
      estado: "",
      segmento: "",
      categoria: "",
      schema_type: "flat",
      priceOne: 0,
      taxa:false,
      valortaxa: "",
      amount: 0,
      percentage: "",
      cycles: 0,
      payment_method_code: "bank_slip",
      interval_count: 1,
      billing_trigger_type: "beginning_of_period",
      billing_trigger_day: 0,
      plano: "essencial",
      codcnae:"",
      descdivisaocnae:"",
      descclassecnae:""
    },
  });

  useEffect(() => {
    if (errors) {
      Object.keys(errors).forEach((errorKey) => {
        toast.error(`Campo "${errorKey}" inválido`);
      });
    }
  }, [Object.keys(errors)]);

  const isDiscountFixedPrice = discountType == "amount";
  const isFixedPrice = watch("schema_type") == "flat";
  const isMobile = watch("phone_type") == "mobile";
  const isAnnualBilling = watch("interval_count") == 12;
  const isMonthlyBilling = watch("interval_count") == 1;
  const isTriggerDayOfMonth = watch("billing_trigger_type") == "day_of_month";
  const isDocumentTypeValid = documentType != "outro";
  const isDynamicTable = watch("schema_type") == "dynamic_table";

  useEffect(() => {
    if (!isDocumentTypeValid) setIsProductEnabled(false);
  }, [isDocumentTypeValid]);

  useEffect(() => {
    if (!isMonthlyBilling && isTriggerDayOfMonth)
      setValue("billing_trigger_type", "beginning_of_period");
  }, [isMonthlyBilling, isTriggerDayOfMonth]);

  useEffect(() => {
    const billingTriggerType = watch("billing_trigger_type");
    if (billingTriggerType === "day_of_month") {
      setValue("billing_trigger_day", 5);
    }
  }, [watch("billing_trigger_type")]);

  const handleSetResultOptionSelectUser = (userSelected) => {
    setSelectedUser(userSelected);
    setValue("interval_count", userSelected?.EmpresaModel?.assinatura?.qtdinterval);
    setValue("billing_trigger_day", userSelected?.EmpresaModel?.assinatura?.diadacobranca);
    setValue("payment_method_code", userSelected?.EmpresaModel?.assinatura?.metododepagamento);
  };

  const onSubmit = (data) => {
    const discountSchema = {
      discount_type: discountType,
      amount: isDiscountFixedPrice ? data.amount : null,
      percentage: !isDiscountFixedPrice ? data.percentage : null,
      cycles: data.cycles,
    };
    if (documentType == "outro") {
      data.cnpj = "";
    } else {
      data.outrosdocumentos = "";
    }

    if (data.datafimteste) {
      const selectedDate = new Date(data.datafimteste);
      selectedDate.setHours(23, 59, 59, 999);
  
      data.datafimteste = selectedDate.toISOString();
    }

    const body = {
      ...data,
      taxa: isTaxEnabled,
      outrosdocumentos: data.outrosdocumentos,
      associar: +isAssociation,
      cnpj: removeMask(data.cnpj),
      criarvindi: isProductEnabled ? 1 : 0,
      discount: +isDiscountEnabled,
      discount_schema: +isDiscountEnabled ? discountSchema : null,
      fuso: -3,
      numero: data.numeroResidencial,
      interval: "months",
      phones: [
        {
          phone_type: data.phone_type,
          number: data.number,
          extension: null,
        },
      ],
      pricing_schema: {
        price: isFixedPrice ? data.priceOne : 0,
        schema_type: data.schema_type,
        pricing_ranges: !isFixedPrice
          ? ranges.map((range) => ({
              ...range,
              price: parseFloat(range.price),
              overage_price: parseFloat(range.overage_price),
            }))
          : "",
      },
      usuario: selectedUser,
      tipocadastro: selectedUsage,
      datafimteste: data.datafimteste || null,
    };

    if (validateDocument && documentType == "cnpj") {
      createCompany(body);
    } else if (documentType !== "cnpj") {
      createCompany(body);
    }
  };

  const createCompany = async (body) => {
    const toastId = toast.loading("Criando empresa...");
    try {
      await postCompany(body);

      toast.success("Empresa criada com sucesso!", 4000);

      setTimeout(() => {
        history.push("/");
      }, 2000);
    } catch (err) {
      toast.error(err.message, 4000);
    }
    toast.dismiss(toastId);
  };
  const copyToClipboard = (args) => {
    toast.success("Código CNAE copiado com sucesso!", { autoClose: 4000 });
    const value = getValues(args); 
    if (value) {
      navigator.clipboard.writeText(value).then(() => {});}
  };
  
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md="3">
          <label>Tipo de documento</label>
          <SelectDocumentType setValue={setDocumentType} value={documentType} />
        </Col>
        <Col md="3">
          <label>Documento *</label>
          <Controller
            name={documentType == "outro"?"outrosdocumentos":"cnpj"}
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => {
              if (documentType == "cnpj") {
                return (
                  <CnpjInput
                    setValue={setValue}
                    setValidateDocument={(bool) => setValidateDocument(bool)}
                    validateDocument={validateDocument}
                    {...field}
                  />
                );
              } else if (documentType == "cpf") {  
                setValue("codcnae", null);
                setValue("descdivisaocnae", null);
                setValue("descclassecnae", null);            
                return <CpfInput {...field} />;
              } else {
                setValue("codcnae", null);
                setValue("descdivisaocnae", null);
                setValue("descclassecnae", null);
                return <OutrosInput {...field} />;
              }
            }}
          />
        </Col>
        <Col md="3">
          <label>Razão Social *</label>
          <Controller
            name="razaosocial"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => <Input {...field} />}
          />
        </Col>
        <Col md="3">
          <label>Nome Fantasia *</label>
          <Controller
            name="nomefantasia"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => <Input {...field} />}
          />
        </Col>
        <Col md="3">
          <label>Nome do banco de dados *</label>
          <Controller
            name="nomedobanco"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                {...field}
                required
                onChange={(e) => field.onChange(maskDbName(e.target.value))}
              />
            )}
          />
        </Col>
        <Col md="3">
          <label>Email *</label>
          <Controller
            name="emailcobranca"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => <Input {...field} type="email" required />}
          />
        </Col>
        <Col md="3">
          <label>Tipo de Celular *</label>
          <Controller
            name="phone_type"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <select {...field} className="form-control">
                <option value="mobile" selected>
                  Celular
                </option>
                <option value="landline">Telefone</option>
              </select>
            )}
          />
        </Col>

        <Col md="3">
          <label>{isMobile ? "Celular" : "Telefone"} *</label>
          <Controller
            name="number"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                {...field}
                required
                onChange={(e) => {
                  field.onChange(
                    isMobile ? maskPhone(e.target.value) : maskLandline(e.target.value)
                  );
                }}
              />
            )}
          />
        </Col>
      </Row>

      <Row>
        <Col md="3">
          <label>CPF do responsável</label>
          <Controller
            name="cpfresponsavel"
            control={control}
            render={({ field }) => (
              <Input {...field} onChange={(e) => field.onChange(maskCpf(e.target.value))} />
            )}
          />
        </Col>
        <Col md="3">
          <label>CNO</label>
          <Controller
            name="cno"
            control={control}
            render={({ field }) => <Input {...field} maxLength={12} />}
          />
        </Col>
        <Col md="3">
          <label>CAEPF</label>
          <Controller
            name="caepf"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                maxLength={18}
                onChange={(e) => field.onChange(maskCaepf(e.target.value))}
              />
            )}
          />
        </Col>
      </Row>

      <Row>
        <Col md="3">
          <label>CEP</label>
          <Controller
            name="cep"
            control={control}
            render={({ field }) => <CepInput setValue={setValue} {...field} />}
          />
        </Col>
        <Col md="3">
          <label>Endereço</label>
          <Controller
            name="endereco"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </Col>
        <Col md="3">
          <label>Bairro</label>
          <Controller
            name="bairro"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </Col>
        <Col md="3">
          <label>Número</label>
          <Controller
            name="numeroResidencial"
            control={control}
            render={({ field }) => <Input {...field} type="number" />}
          />
        </Col>
        <Col md="3">
          <label>Complemento</label>
          <Controller
            name="complemento"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </Col>
        <Col md="3">
          <label>Cidade</label>
          <Controller
            name="cidade"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </Col>
        <Col md="3">
          <label>Estado</label>
          <Controller
            name="estado"
            control={control}
            render={({ field }) => <Input {...field} maxLength={2} placeholder="UF" />}
          />
        </Col>
      </Row>

      <Row>
        <Col md="3">
          <label>Tipo de Plano *</label>
          <Controller
            name="plano"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <SelectPlans
                openModal={() => {
                  setIsOpenPlanType(true);
                }}
                {...field}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <h4 className="title">Situação Cadastral</h4>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <label>Tipo de Cadastro *</label>
          <Controller
            name="tipocadastro"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <select
                {...field}
                className="form-control"
                onChange={(e) => {
                  const value = e.target.value;
                  field.onChange(value);
                  setSelectedUsage(value);
                }}
              >              
                <option value="interno">Uso Interno</option>
                <option value="cliente">Cliente</option>
                <option value="teste">Teste Gratuito</option>
              </select>
            )}
          />
        </Col>
        {selectedUsage === "teste" && (
          <Col md="3">
            <label>Data Fim *</label>
            <Controller
              name="datafimteste"
              control={control}
              rules={{
                required: true,
                validate: (value) => {
                  if (!value) return "Data obrigatória";
                  
                  const datePattern = /^\d{4}-\d{2}-\d{2}$/;
                  if (!datePattern.test(value)) return "Formato inválido";
          
                  const year = parseInt(value.split("-")[0], 10);
                  if (year > 9999) return "Ano inválido";
                  
                  return true;
                }
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="date"
                  className="form-control"
                  onFocus={(e) => e.target.showPicker()}
                  onChange={(e) => {
                    let value = e.target.value;
                    const year = value.split("-")[0];
          
                    if (year.length > 4) {
                      value = value.slice(0, 4) + value.slice(5);
                    }
                    
                    field.onChange(value);
                  }}
                />
              )}
            />
          </Col>
        )}
      </Row>
      
      <Row>
        <Col>
          <h3 className="title">Segmentação</h3>
        </Col>
      </Row>

      <Row
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Col md="3">
          <label>Segmento *</label>
          <Controller
            name="segmento"
            control={control}
            render={({ field }) => <Input {...field} />}
            rules={{
              required: true,
            }}
          />
        </Col>
        <Col md="3">
          <label>Categoria *</label>
          <Controller
            name="categoria"
            control={control}
            render={({ field }) => <Input {...field} />}
            rules={{
              required: true,
            }}
          />
        </Col>
        {documentType ==="cnpj"?
        <Col md="3">
          <label>Código CNAE *</label>
          <Controller
            rules={{
              required: true,
            }}
            name="codcnae"
            control={control}
            render={({ field }) => <Input {...field} disabled />}
          />
        </Col>:null}
        {documentType ==="cnpj"?
        <img
          src={ClipboardIcon}
          alt="Copiar Código CNAE"
          onClick={() => copyToClipboard("codcnae")}
          style={{ 
            cursor: "pointer", 
            marginLeft: "10px", 
            width: "24px", 
            height: "24px" 
          }}
        />:null}
        {documentType ==="cnpj"?
        <Col md="3">
          <label>Descrição Divisão CNAE *</label>
          <Controller
            rules={{
              required: true,
            }}
            name="descdivisaocnae"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </Col>:null}
        {documentType ==="cnpj"?
        <Col md="3">
          <label>Descrição Classe CNAE *</label>
          <Controller
            rules={{
              required: true,
            }}
            name="descclassecnae"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </Col>:null}
      </Row>

      <Row>
        <Col>
          <h3 className="title">Associar Empresa</h3>
        </Col>
      </Row>

      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Col md="3">
          <label>Associar</label>
          <SelectYesOrNo
            positiveValue="Sim"
            negativeValue="Não"
            disabled={!isProductEnabled}
            stateComponent={isAssociation}
            onChange={(e) => setIsAssociation(e.target.value)}
          />
        </Col>

        {isAssociation && (
          <Col md={3} style={{ marginTop: "-5px" }}>
            <label>Usuario </label>
            <SelectSearchUsers handleSetResultOptionSelectUser={handleSetResultOptionSelectUser} />
          </Col>
        )}

        {isDocumentTypeValid && (
          <Col md="6">
            <Button
              onClick={() => {
                setIsProductEnabled((prev) => !prev);
              }}
              tag="text"
              color="info"
            >
              {!isProductEnabled ? "Habilitar Produto" : "Desabilitar Produto" }
            </Button>
          </Col>
        )}
      </Row>

      <Row style={{ marginTop: "30px" }}>
        <Col>
          <h3 className="title">Dados da Assinatura</h3>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <h4 className="title">Produto</h4>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <label>Tipo de Cálculo *</label>
          <Controller
            name="schema_type"
            control={control}
            render={({ field }) => (
              <select {...field} disabled={!isProductEnabled} className="form-control">
                {/* tipo de calculo */}
                <option value="flat" selected>
                  Preço Fixo
                </option>
                <option value="step_usage">Fixo por Volume</option>
                <option value="volume_usage">Variavel por Volume</option>
                <option value="dynamic_table">Tabela Dinâmica</option>
              </select>
            )}
          />
        </Col>

        {isDynamicTable ? (
          <Col md="3">
          </Col>
        ) :isFixedPrice ? (
          <Col md="3">
            <label>Preço *</label>
            <Controller
              name="priceOne"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  disabled={!isProductEnabled}
                  required
                  onChange={(e) => field.onChange(maskBrl(e.target.value))}
                />
              )}
            />
          </Col>
        ) : (
          <Col md="12">
            <PricingRanges
              isProductEnabled={isProductEnabled}
              priceScheme={watch("schema_type")}
              ranges={ranges}
              setRanges={setRanges}
            />
          </Col>
        )}
      </Row>

      <Row>
        <Col md="3">
          <label>Adicionar taxa *</label>
          <SelectYesOrNo
            positiveValue="Sim"
            negativeValue="Não"
            stateComponent={isTaxEnabled}
            onChange={(e) => setIsTaxEnabled(e.target.value)}
            disabled={!isProductEnabled}
          />
        </Col>
        {isTaxEnabled ? (
          <Col md="3">
            <label>Taxa *</label>
            <Controller
              name="valortaxa"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  disabled={!isProductEnabled}
                  required
                  onChange={(e) => field.onChange(maskBrl(e.target.value))}
                />
              )}
            />
          </Col>
        ) : null}
      </Row>

      <Row>
        <Col md="3">
          <label>Deseja adicionar Desconto?</label>
          <SelectYesOrNo
            positiveValue="Sim"
            negativeValue="Não"
            stateComponent={isDiscountEnabled}
            onChange={(e) => setIsDiscountEnabled(e.target.value)}
            disabled={!isProductEnabled}
          />
        </Col>
        {isDiscountEnabled ? (
          <>
            <Col md="3">
              <label>Tipo de Desconto *</label>
              <select
                disabled={!isProductEnabled}
                onChange={(e) => setDiscountType(e.target.value)}
                className="form-control"
                required
              >
                <option value="amount" selected>
                  Preço Fixo
                </option>
                <option value="percentage">Porcentagem</option>
              </select>
            </Col>
            <Col md="3">
              <label>{isDiscountFixedPrice ? "Preço Fixo *" : "Porcentagem *"}</label>
              <Controller
                name={discountType}
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    disabled={!isProductEnabled}
                    required
                    type={isDiscountFixedPrice ? "text" : "number"}
                    onChange={(e) => {
                      let value = e.target.value;

                      if (isDiscountFixedPrice) {
                        value = maskBrl(value);
                      }

                      // Impede valores acima de 100
                      if (!isDiscountFixedPrice && !isNaN(value) && parseFloat(value) > 100) {
                        value = 100;
                      }

                      return field.onChange(value);
                    }}
                  />
                )}
              />
            </Col>
            <Col md="3">
              <label>Ciclos *</label>
              <Controller
                name="cycles"
                control={control}
                render={({ field }) => (
                  <Input {...field} disabled={!isProductEnabled} type="number" required />
                )}
              />
            </Col>
          </>
        ) : null}
      </Row>

      <Row style={{ marginTop: "30px" }}>
        <Col md="4">
          <h4 className="title">Plano</h4>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <label>Forma de pagamento</label>
          <Controller
            name="payment_method_code"
            control={control}
            render={({ field }) => (
              <select
                {...field}
                disabled={!isProductEnabled || isAssociation}
                name="payment_method_code"
                className="form-control"
              >
                <option disabled selected>
                  Selecione o Método de Pagamento
                </option>
                <option value="bank_slip">Boleto Bancário</option>
                <option value="credit_card">Cartão de Crédito</option>
                <option value="pix">Pix</option>
              </select>
            )}
          />
        </Col>
      </Row>

      <Row>
        <Col md="3">
          <label>Contagem de intervalo *</label>
          <Controller
            name="interval_count"
            control={control}
            render={({ field }) => (
              <SelectIntervalCount
                {...field}
                ref={null}
                schema_type={watch("schema_type")}
                disabled={!isProductEnabled || isAssociation}
                required
                className="form-control"
              />
            )}
          />
        </Col>

        {isAnnualBilling && (
          <Col md="3">
            <label>Limite de Funcionários *</label>
            <Controller
              name="limitefuncionarios"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  disabled={!isProductEnabled}
                  max={100}
                  required={isFixedPrice === "flat" ? true : false}
                />
              )}
            />
          </Col>
        )}

        <Col md="3">
          <label>Referência para data de geração da cobrança *</label>
          <Controller
            name="billing_trigger_type"
            control={control}
            render={({ field }) => (
              <SelectBillingTriggerTypes
                {...field}
                ref={null}
                intervalCount={watch("interval_count")}
                disabled={!isProductEnabled || isAssociation}
                required
                className="form-control"
              />
            )}
          />
        </Col>

        <Col md="3">
          {watch("billing_trigger_type") === "day_of_month" && (
            <>
              <label>Dia de cobrança *</label>
              <Controller
                name="billing_trigger_day"
                control={control}
                render={({ field }) => (
                  <select {...field} ref={null} disabled={!isProductEnabled || isAssociation} required className="form-control">
                    {[5, 10, 15, 20, 25, 30].map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                )}
              />
            </>
          )}
          {["beginning_of_period", "end_of_period"].includes(watch("billing_trigger_type")) && (
            <>
              <label>Dia de cobrança *</label>
              <Controller
                name="billing_trigger_day"
                control={control}
                render={({ field }) => (
                  <select {...field} ref={null} disabled={!isProductEnabled || isAssociation} required className="form-control">
                    <option value="0">Cobrança Imediata</option>
                  </select>
                )}
              />
            </>
          )}
        </Col>
      </Row>

      <Button disabled={false} className="btn-fill" color="primary" type="submit">
        Salvar
      </Button>
      <ModalConfirm
        modal={isOpenPlanType}
        handleConfirm={() => {
          setIsOpenPlanType(false);
          setValue("plano", "avancado");
        }}
        toggle={() => {
          setIsOpenPlanType(false);
          setValue("plano", "essencial");
        }}
        title={"Deseja ativar o plano avançado para essa empresa?"}
        subTitle=""
      />
    </Form>
  );
}
