import { useEffect } from "react";
import { InputPassword } from "components/InputPassword";
import SelectCompanies from "components/SelectCompanies";
import { Controller, useForm } from "react-hook-form";
import { Button, Col, Form, Input, Row } from "reactstrap";
import { maskCpf } from "utils/masks";
import { toast } from "react-toastify";

export default function CreateUserForm({ onSubmit }) {
  const { handleSubmit, control, formState: { errors } } = useForm({
    defaultValues: {
      empresa: null,
      nome: "",
      cpf: "",
      email: "",
      login: "",
      senha: "",
    },
  });

  useEffect(() => {
    if(errors) {
      Object.keys(errors).forEach((errorKey) => {
        toast.error(`Campo "${errorKey}" inválido`);
      });
    }
  }, [Object.keys(errors)]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md="6">
          <label>Empresa *</label>
          <Controller
            name="empresa"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <SelectCompanies onSelect={(item) => field.onChange(item.id)} />
            )}
          />
        </Col>
        <Col md="6">
          <label>Nome *</label>
          <Controller
            name="nome"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => <Input required {...field} />}
          />
        </Col>
        <Col md="6">
          <label>CPF</label>
          <Controller
            name="cpf"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onChange={(e) => field.onChange(maskCpf(e.target.value))}
                maxLength={14}
              />
            )}
          />
        </Col>
        <Col md="6">
          <label>Email *</label>
          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => <Input {...field} type="email" required />}
          />
        </Col>

        <Col md="6">
          <label>Login *</label>
          <Controller
            name="login"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => <Input {...field} />}
          />
        </Col>

        <Col md="6">
          <label>Senha *</label>
          <Controller
            name="senha"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => <InputPassword {...field} />}
          />
        </Col>
      </Row>

      <Button
        disabled={false}
        className="btn-fill"
        color="primary"
        type="submit"
      >
        Salvar
      </Button>
    </Form>
  );
}
